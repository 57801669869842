import React from 'react'

const Search = props => (
  <svg viewBox="0 0 14 14.5" {...props}>
    <path d="M13.8 13.2l-3.5-3.6c.9-1.1 1.4-2.4 1.4-3.8C11.7 2.6 9.1 0 5.9 0S0 2.6 0 5.9s2.6 5.9 5.9 5.9c1.2 0 2.4-.4 3.4-1.1l3.5 3.6c.1.2.3.2.6.2.2 0 .4-.1.5-.2.2-.3.2-.8-.1-1.1zM5.9 1.5c2.4 0 4.3 1.9 4.3 4.3s-1.9 4.3-4.3 4.3-4.4-1.9-4.4-4.2 2-4.4 4.4-4.4z" />
    <defs>
      <linearGradient id={`${props.section}_search_blog_gradient`}>
        <stop offset="5%" stopColor="#f95360" />
        <stop offset="95%" stopColor="#f84f5f" />
      </linearGradient>
    </defs>
  </svg>
)

export default Search