import React from 'react';
import { Link } from 'gatsby';
import blogStyles from '../../styles/_components/_blog/_blog_item.module.scss';
import { TextGradient } from '../../components/_text/_text_gradient';
import { ButtonSemicircle,Button_Semicircle_types } from '../../components/buttons/_button_semicircle';
import { Picture } from 'react-responsive-picture';

export const BlogItemTypes = {
    MAIN: 'oBlogItem__main',
    TO_LEFT: 'oBlogItem__left',
    TO_RIGHT: 'oBlogItem__right',
    SINGLE: 'oBlogItem__single',
}

export const BlogItem=({blog,type,single_layout='',_className,_related=false})=>{
    let short_description =  blog.node.data.content.text !== null ? blog.node.data.content.text.length > 120 ? blog.node.data.content.text.substring(0,120)+' ...' : blog.node.data.content.text : " ";
    let single_layout_class = type=== BlogItemTypes.SINGLE ? blogStyles[`oBlogItem__single${single_layout}`] : '';

    return (
        <div className={`${_className} ${_className+'-'+single_layout} ${blogStyles.oBlogItem} ${blogStyles[type || BlogItemTypes.SINGLE]} ${single_layout_class}`}>
            <div className={blogStyles.oBlogItemSectionHeader}>
                <div className={blogStyles.oBlogItemSectionHeader__bg}></div>
                <p className={blogStyles.oBlogItemSectionHeader__text}>Calorex</p>
            </div>
            <div className={`${blogStyles.oBlogItemStage} o-blog-item_stage`}>
                <div className= {blogStyles.oBlogItemImg__container} >
                    <p className={`${blogStyles.oBlogItemBlogtype} o-blog-item_blogtype`}>{blog.node.data.type.document[0].data.name.text}</p>
                    <Link to={`/blog/${blog.node.data.type.document[0].uid}/${blog.node.uid}`}>
                        <Picture
                            sources = {[
                            {
                                srcSet: blog.node.data.menu_image.sm.url,
                                media: "(max-width: 768px)",
                            },
                            {
                                srcSet: blog.node.data.menu_image.md.url,
                                media: "(max-width: 1023px)",
                            },
                            {
                                srcSet: blog.node.data.menu_image.url,
                                media: "(min-width: 1024px)",
                            }
                            ]}
                        />
                    </Link>
                </div>
                <div className={blogStyles.oBlogItemInfo__container}>
                    <div className="">
                        <p className={blogStyles.oBlogItemBlogtype}>{blog.node.data.type.document[0].data.name.text}</p>
                        <TextGradient _text={blog.node.data.title.text} _className={blogStyles.oBlogItem__title}></TextGradient>
                        <p className={`o-blog-item_content ${blogStyles.oBlogItemContent}`}>{short_description}</p>
                    </div>
                    <div className={blogStyles.oBlogItem__button_container}>
                        <ButtonSemicircle
                            _slug={blog.node.uid}
                            _text='Leer Nota'
                            _to={`/blog/${blog.node.data.type.document[0].uid}/${blog.node.uid}`}
                            _className={`blog_more_button_item`}
                            _button_type={Button_Semicircle_types.LIGHT}
                            >
                        </ButtonSemicircle>
                    </div>
                </div>
            </div>
        </div>
    );
}