import React,{useEffect,useState} from 'react';
import styles from '../../styles/_components/_text/_text_gradient.module.scss';

export const TextGradient=({_text,_className=null,_color_start='#ba0c2f',_color_end='#d90b2d',_deg=0})=>{
    let class_style =null;
    let deg = _deg!==0 ? _deg+'deg,' : ''
    const [styleVar, setStyleVar] = useState({ color: `${_color_start}` });

    if (_color_start === null) {
        _color_start = '#ba0c2f';
    }
    if (_color_end === null) {
        _color_end = '#d90b2d';
    }

    useEffect(() => {   
         /* eslint-disable */
        if(document!==null){

            let ie = (document.documentMode ? document.documentMode : 99)
            if(_color_start!=='' && _color_end!=='')
            {
                // console.log('ieversion',ie);
                if(ie<99)
                {
                    setStyleVar({ color: `${_color_start}`});
                }
                else{
                    setStyleVar({ backgroundImage: `linear-gradient(${deg} ${_color_start} 0%, ${_color_end} 100%)`});
                }
            }
        }
    }, [])
    class_style = styles.oTextGradient;
    return (
        <p className={`o-text-gradient ${class_style} ${_className}`} style={styleVar}>
            {_text}
        </p>
    )
}
