import React,{useRef, useState, useEffect} from 'react';
import Search from '../svg/commons/search';
import styles from '../../styles/_components/_blog/_blog_search.module.scss';
import {BlogItem,BlogItemTypes} from '../_blog/_blog_item';
import { usePrismBlogs } from '../../hooks/use_prism-blogs';
import  CloseIcon from  '../../components/svg/commons/close_icon';

export const BlogSearch= ()=>{
     
    const blogs = usePrismBlogs();
 
    // for(let i =0; i<6; i++){              
    //   blog_array.push({blog: blogs.edges[i], type:BlogItemTypes.SINGLE,single_layout: single_layout_array[to_add]});
    //   to_add++
    //    if(to_add===3) setToAdd(0);
    // }    
    
    const search_button = useRef(null);
    const search_input = useRef(null);
    const [update, setUpdate] = useState(false);
    const [newBlogs, setNewBlogs] = useState([]);
    
    
    useEffect(() => {
      let is_busy = false;
      let to_add =0
      let blog_array = []
      let blog_total = blogs.edges.length;
      const single_layout_array = ['left','middle','right']
      
        if(update) {
            if(is_busy)return;
            is_busy=true;
            
            let val = search_input.current.value;         
            
            for(let i =0; i<blog_total; i++){              
              if(blogs.edges[i].node.data.title.text.includes(val)){
                
                blog_array.push({blog: blogs.edges[i], type:BlogItemTypes.SINGLE,single_layout: single_layout_array[to_add]});
                to_add++;
                if(to_add===3) to_add =0;
                 
              }              
            }
            
            setNewBlogs(blog_array)
            setUpdate(false)
        }
    }, [update, blogs]);

  function close_search(){
        
    let element;
    element = document.getElementsByClassName("stage")[0];        
    element.className = element.className.replace(/\bstage--onsearch\b/g, "");
  }
    return (
     
        <div className={`blog-search ${styles.blog_search_container}`}>
            <div className={styles.blog_search_header} >
                <div className={styles.blog_search_icon} ref={search_button}>
                    <Search section="blog_search"></Search>
                </div>
                <input className={styles.blog_search_input} ref={search_input} type="text" placeholder="buscar..." onKeyUp={()=>setUpdate(true)}></input>
                <div className={styles.blog_search_header_spacer}></div>
                <div className={styles.blog_search_header_x} onClick={()=>close_search()}><CloseIcon></CloseIcon></div>
            </div>
            <div className="blog_search_body">
                <div className={styles.blog_search_item_container}>                  
                  {newBlogs.map((item,key) => <BlogItem key={`blog_item_${item.blog.node.uid}`} blog={item.blog} type={item.type} single_layout={item.single_layout}></BlogItem> )}
                </div>
            </div>
        </div>
    )
}