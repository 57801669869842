import React from 'react'
import { Link } from 'gatsby'
import '../../components/buttons/_button_semicircle';
// import { MenuSlide } from '../../components/_menu/_menu_slide';
//import { ButtonSemicircle,Button_Semicircle_types } from '../../components/buttons/_button_semicircle';

//import { usePrismTypes } from '../../hooks/use-prism-blog-types';
// import Search from '../../components/svg/commons/search'

const BlogMenu = (props)=>{
   //  const blog_types=usePrismTypes();
   // eslint-disable-next-line
    // function create_dynamic_items(_blog_types){
    //     return _blog_types.edges.map( (_item,_index) => {
                               
    //         return(
    //             create_header_menu_item(_item.node.data.name.text,_item.node.uid,props.path==='/blog/'+_item.node.uid)
    //         )
    //     });
    // }

    // function create_header_menu_item(_name,_slug,_is_selected=false){
        
    //     return (
    //         <ButtonSemicircle 
    //         key={`o-button-semicircle--${_slug}`}
    //             _to={`/blog/${_slug}`}
    //             _slug={`semicircle_${_slug}`} 
    //             _text={_name} 
    //             _className={`blog_header_type_item`}
    //             _activeClassName= 'o-button-semicircle--selected'
    //             _button_type={Button_Semicircle_types.REGULAR}
    //             _is_selected= {_is_selected}
    //             >
    //         </ButtonSemicircle>
    //     );
    // }
    // function open_search(){
        
    //     let element, name, arr;
    //     element = document.getElementsByClassName("stage")[0];        
    //     name = "stage--onsearch";
    //     arr = element.className.split(" ");
    //     if (arr.indexOf(name) === -1) {
    //         element.className += " " + name;
    //     }
    // }
    return (
        <Link className="blog_header" to='/blog'>
            <h2 className="blog_header_title">Blog Calorex</h2>
            {/* <div className="blog_header_search_container">
                <div className="blog_header_seach_icon_container" onClick={() => open_search() }>
                    <Search section="blog_header"></Search>
                </div>
            </div> */}
            {/* <MenuSlide>
                {create_header_menu_item('Todas','',props.path==='/blog/' )}
                {create_dynamic_items(blog_types)}
            </MenuSlide>                 */}
        </Link>
    )
    
}

export default BlogMenu;
