import React from 'react';
// import { Link } from 'gatsby';
import styles from '../../styles/_components/_share/_share_blog.module.scss';
import Facebook from '../svg/commons/facebook'
import Twitter from '../svg/commons/twitter'

// export const Button_Semicircle_types = {
//     LIGHT: 'oButtonSemicircleLight',
//     REGULAR: ''
//   }

export const ShareBlog = ({_fb_to='',_tw_to='',_className='' ,_color_start='',_color_end='',_section=''}) => {
    return (
        <div className={`o-share-blog__container ${_className}`}>
            <p className={styles.share_blog_title}>Compartir</p>
            {_fb_to!=='' ? <a href={`https://facebook.com/sharer.php?display=page&u=https://calorex.com${_fb_to}`} target="_blank"  rel="noopener noreferrer"><Facebook color_start={_color_start} color_end={_color_end} section={_section} fill={`url(#${_section}_fb_icon)`}></Facebook></a> : null}
            {_tw_to!=='' ? <a href={`https://twitter.com/share?text=visita&url=https://calorex.com${_tw_to}`} target="_blank"  rel="noopener noreferrer"><Twitter color_start={_color_start} color_end={_color_end} section={_section} fill={`url(#${_section}_fb_icon)`}></Twitter></a> : null}
        </div>

    )
}

