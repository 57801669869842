import React from "react"
import Layout from '../components/layout';

import { TextGradient } from '../components/_text/_text_gradient';
import { ButtonArrow } from '../components/buttons/_btn_arrow';

import BlogMenu from '../content/blog/blog_menu';

import "../styles/pages/blog/blog.scss"
import { BlogItem,BlogItemTypes  } from '../components/_blog/_blog_item';

import {  ShareBlog } from '../components/_share/_share_blog';
import { MenuSlide } from '../components/_menu/_menu_slide';
import { usePrismBlogs } from '../hooks/use_prism-blogs';
import { BlogSearch } from '../components/_blog/_blog_search';

import { Picture } from 'react-responsive-picture';
import GetBodyItem from '../components/_helpers/_get_body_item'

const BlogDetail = ( props ) => {

    let _item = props.pageContext.item;
    const blogs= usePrismBlogs().edges;    
    
    let metas_from_prismic = new GetBodyItem().get_item(_item.node,"PrismicBlogBodyMetas");
    let current_blog_uid = _item.node.uid;
    let blogs_clean = [];
    for(let i=0;i<blogs.length;i++){
        if(blogs[i].node.uid!==current_blog_uid)
        {
            blogs_clean.push(blogs[i]);
        }
    }
    let metas= {
        title:'Calorex Blog',
        description: 'Calorex Blog Des',
        image:''
    }
    if(metas_from_prismic)
    {
        metas.title = metas_from_prismic.primary.title.text
        metas.description = metas_from_prismic.primary.description.text
        metas.image= metas_from_prismic.primary.image_share.url
    }

    function set_date_format(_date){
        let date_array = _date.split('-');
        const months= ['','Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];
        return date_array[2]+' de '+months[parseInt(date_array[1])]+' '+date_array[0];
    }
    function get_detail(_item){
        return(
            <div className="blog_detail_container">
                <p className="blog_detail_date">{set_date_format(_item.node.data.date)}</p>
                <div className="blog_detail_content_top blog_detail_content" dangerouslySetInnerHTML={{__html:_item.node.data.content.html}}></div>
                {_item.node.data.body!== null ?
                    _item.node.data.body.map((_block, index)=>{
                        switch(_block.__typename){
                            // case "PrismicBlogBodyCopy":
                            //     return (
                            //         <TextGradient
                            //         _text={_block.primary.title1.text}
                            //         _color_start='#fc6366'
                            //         _color_end='#f43b59'
                            //         _deg={180}
                            //         _className={'blog_detail_content_copy'} />
                            //     )
                            // break;
                            case "PrismicBlogBodyTexto":
                                return (
                                    <div key={index} className="blog_detail_content_bottom blog_detail_content" dangerouslySetInnerHTML={{__html: _block.items[0].description.html}}></div>
                                )
                            
                            default :
                                return(null);
                        }
                    })
                : null
                }
            </div>
        );

    }

    return (
        <Layout>
            <BlogSearch></BlogSearch>
            <div className="blog-container blog-container--on-detail">
                <div className="blog_header_stage">
                    <div className="blog_carrousel">
                        <TextGradient
                        _text={_item.node.data.title.text}
                        _color_start='#d70b2d'
                        _color_end='#bc0c2f'
                        _deg={180}
                        _className={'blog_carrousel_item__title'} />
                        <Picture
                            sources = {[
                            {
                                srcSet: _item.node.data.main_image.sm.url,
                                media: "(max-width: 768px)",
                            },
                            {
                                srcSet: _item.node.data.main_image.md.url,
                                media: "(max-width: 1024px)",
                            },
                            {
                                srcSet: _item.node.data.main_image.lg.url,
                                media: "(max-width: 1365px)",
                            },
                            {
                                srcSet: _item.node.data.main_image.url,
                                media: "(min-width: 1366px)",
                            }
                            ]}
                        />
                    </div>
                    <BlogMenu path={`/blog/${_item.node.data.type.document[0].uid}`}></BlogMenu>
                    <ButtonArrow _className='blog_detail_item__return' _text='Regresar' _to={"/blog"} />
                </div>
                <div className="blog_body">
                    <div className='blog_body_main'>

                        <div className="blog_detail_share">
                            <ShareBlog _fb_to={`/blog/${_item.node.data.type.document[0].uid}/${_item.node.uid}`} _tw_to={`/blog/${_item.node.data.type.document[0].uid}/${_item.node.uid}`} _section='blog_detail'></ShareBlog>
                        </div>
                        {get_detail(_item)}
                    </div>
                    <div className='blog_body_related'>
                        <p className='blog_body_related_title'>Noticias Relacionadas:</p>
                        <MenuSlide _className="blog_body_related_slider">
                            {
                                blogs_clean[0]!==undefined ?
                                    <BlogItem blog={blogs_clean[0]} _className="blog_body_related_slider_item" type={BlogItemTypes.SINGLE} single_layout='left'></BlogItem>
                                : null
                            }

                            {
                                blogs_clean[1]!==undefined ?
                                <BlogItem blog={blogs_clean[1]} _className="blog_body_related_slider_item" type={BlogItemTypes.SINGLE} single_layout='middle'></BlogItem>
                                : null
                            }
                            {
                                blogs_clean[2]!==undefined ?
                                <BlogItem blog={blogs_clean[2]} _className="blog_body_related_slider_item" type={BlogItemTypes.SINGLE} single_layout='middle'></BlogItem>
                                : null
                            }
                            {
                                blogs_clean[3]!==undefined ?
                                <BlogItem blog={blogs_clean[3]} _className="blog_body_related_slider_item" type={BlogItemTypes.SINGLE} single_layout='right'></BlogItem>
                                : null
                            }
                        </MenuSlide>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default BlogDetail
