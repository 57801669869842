import { useStaticQuery, graphql } from "gatsby"
export const usePrismBlogs = () => {
const prismic =useStaticQuery(
    graphql`{
        allPrismicBlog(sort: {fields: last_publication_date, order: DESC}){
            edges{
              node{
                uid
                type
                first_publication_date
                
                data{
                  date
                  title{
                    text
                  }
                  content{
                    html
                    text
                  }
                  type{
                    link_type
                    ... on PrismicBlogDataType{
                        document{
                        uid
                        type
                        data{
                          name{
                            html
                            text
                          }
                        }
                      }
                    }
                  }
                  body{
                    __typename
                    ... on PrismicBlogBodyCopy{
                      primary{
                        title1{
                          html
                          text
                        }
                      }
                    }
                    __typename
                    ... on PrismicBlogBodyTexto{
                      items{
                        description{
                          html
                          text
                        }
                      }
                    }
                  }
                  main_image{
                    url
                    sm{
                      alt
                      url
                    }
                    md{
                      url
                    }
                    lg{
                      url
                    }
                  }
                  menu_image{
                    url
                    sm{
                      url
                    }
                    md{
                      url
                    }
                  }
                  
                  
                }
              }
            }
          }
      }
    `
  );

return prismic.allPrismicBlog

  
}